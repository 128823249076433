import axios from "axios";
import { PostOrder, SaveOrder } from ".";
import { getMobileOperatingSystem } from "../common/app";
import { eraseCookie } from "../common/cookies";
import { promoCodeKey } from "../constants";
import { baseUrl } from "../serverConfig";
import {
  BusinessInfo,
  FinalOrderPayload,
  TrackOrderPayload,
} from "../Types/Types";

const normalizeFinalOrder = (finalOrder: FinalOrderPayload) => {
  const orderPayload = { ...finalOrder };

  // clean finalOrder products
  let cleanedFinalOrderItems: any = [];
  finalOrder.finalOrderItems.forEach((item) => {
    const normalizedItemToSend = {
      price: item.price,
      name: item.name,
      optionName: item.optionName,
      optionsType: item.optionsType,
      type: item.type,
      productOptionId: item.productOptionId,
      count: item.count,
      discountRule: item.discountRule,
      selectedCustomization: item.selectedCustomization,
    };
    cleanedFinalOrderItems.push(normalizedItemToSend);
  });

  orderPayload.phoneOS = getMobileOperatingSystem();
  orderPayload.finalOrderItems = cleanedFinalOrderItems;
  if (window) {
    orderPayload.sourceApp = window.location.origin;
  }

  return orderPayload;
};

export const PaymentSheetHelper = async (order: FinalOrderPayload) => {
  try {
    const payload = normalizeFinalOrder(order);
    const { status, data } = await axios.post(
      `${baseUrl}/rest/order/paymentSheetHelper`,
      payload
    );
    console.log("----- PaymentSheetHelper ORDER PAYLOAD: ", payload);
    return { status, data };
  } catch (err) {
    if (axios.isAxiosError(err)) {
      return { status: err.code, data: err };
    }

    return { status: 500, data: err };
  }
};

export interface OrderSubmissionOptions {
  total?: number;
  businessInfo: BusinessInfo | null;
  nonceToken?: string;
  creditCardKey?: string;
  url?: string;
  beforeSubmission?: (option: TrackOrderPayload) => void;
}

export interface OrderSubmissionResponse {
  paymentStatus?: string;
  success?: boolean;
  orderNumber?: number;
}
export interface OrderSaveResponse {
  paymentStatus?: string;
  success?: boolean;
  orderNumber?: number;
}

export const submitOrder = async (
  finalOrder: FinalOrderPayload,
  options?: OrderSubmissionOptions
): Promise<OrderSubmissionResponse | string> => {
  try {
    const orderPayload = {
      token: options?.nonceToken ?? null,
      creditCardKey: options?.creditCardKey ?? null,
      order: {
        ...finalOrder,
        businessId: options?.businessInfo?.businessId ?? finalOrder.businessId,
        serviceProviderUserId:
          options?.businessInfo?.serviceProviderUserId ??
          finalOrder.serviceProviderUserId,
      },
    };

    // clean finalOrder products
    let cleanedFinalOrderItems: any = [];
    finalOrder.finalOrderItems.forEach((item) => {
      const normalizedItemToSend = {
        price: item.price,
        name: item.name,
        optionName: item.optionName,
        optionsType: item.optionsType,
        type: item.type,
        productOptionId: item.productOptionId,
        count: item.count,
        discountRule: item.discountRule,
        selectedCustomization: item.selectedCustomization,
      };
      cleanedFinalOrderItems.push(normalizedItemToSend);
    });

    orderPayload.order.phoneOS = getMobileOperatingSystem();
    orderPayload.order.total = options?.total ?? finalOrder.total;
    orderPayload.order.finalOrderItems = cleanedFinalOrderItems;
    if (window) {
      orderPayload.order.sourceApp = window.location.origin;
    }

    if (options?.beforeSubmission) options.beforeSubmission(orderPayload);

    console.log("ORDER PAYLOAD: ", orderPayload);

    const res = await PostOrder(orderPayload);
    if (res.success) {
      eraseCookie(promoCodeKey);
    }

    return res;
  } catch (error) {
    console.error(error);
    alert("something went wrong");
    return { success: false };
  }
};

export const saveOrderForLater = async (
  finalOrder: FinalOrderPayload,
  options?: OrderSubmissionOptions
): Promise<OrderSaveResponse | string> => {
  try {
    const orderPayload = {
      token: options?.nonceToken ?? null,
      creditCardKey: options?.creditCardKey ?? null,
      order: {
        ...finalOrder,
        businessId: options?.businessInfo?.businessId ?? finalOrder.businessId,
        serviceProviderUserId:
          options?.businessInfo?.serviceProviderUserId ??
          finalOrder.serviceProviderUserId,
      },
    };

    // clean finalOrder products
    let cleanedFinalOrderItems: any = [];
    finalOrder.finalOrderItems.forEach((item) => {
      const normalizedItemToSend = {
        price: item.price,
        name: item.name,
        optionName: item.optionName,
        optionsType: item.optionsType,
        type: item.type,
        productOptionId: item.productOptionId,
        count: item.count,
        discountRule: item.discountRule,
        selectedCustomization: item.selectedCustomization,
      };
      cleanedFinalOrderItems.push(normalizedItemToSend);
    });

    orderPayload.order.phoneOS = getMobileOperatingSystem();
    orderPayload.order.total = options?.total ?? finalOrder.total;
    orderPayload.order.finalOrderItems = cleanedFinalOrderItems;
    if (window) {
      orderPayload.order.sourceApp = window.location.origin;
    }

    if (options?.beforeSubmission) options.beforeSubmission(orderPayload);

    console.log("ORDER PAYLOAD: ", orderPayload);

    const res = await SaveOrder(orderPayload);
    if (res.success) {
      eraseCookie(promoCodeKey);
    }

    return res;
  } catch (error) {
    console.error(error);
    alert("something went wrong");
    return { success: false };
  }
};
